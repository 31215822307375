:root {
	--container-mw: 1360px;
	--container-py: 20px;
	--line: linear-gradient(to right, #12D0FF, #FFC601);
	--line-v: linear-gradient(#12D0FF, #FFC601);
	--header-h: 72px;
}

/* RESET */
* {box-sizing: border-box; margin: 0; padding: 0}

/* ELEMENTS */
html {font-size: 10px; scroll-behavior: smooth}
body {font-family: 'Satoshi', sans-serif; font-size: 1.6rem; font-weight: 400; color: #171717; line-height: 1.4; letter-spacing: 0; background:#f2f4f8; min-width: 320px; width: 100%; height: 100vh; height: 100dvh; padding-top: var(--header-h); margin: 0; overflow-x: hidden; -webkit-tap-highlight-color: transparent; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale}
h1, h2, h3 {font-weight: 600; margin: 0}
::-moz-selection {background: #12D0FF; color: #000}
::selection {background: #12D0FF; color: #000}
u {text-decoration: none; position: relative}
u::after {content: ''; position: absolute; width: 0; height: 3px; opacity: 0; display: inline-block; background: #12D0FF; border-radius: 6px; bottom: 0; left: 0; animation: u .3s ease .5s forwards; transition: all .2s ease}
a {color: #2E2E2E; text-decoration: none}
a:hover {text-decoration: none}
a > u:after {background-color: transparent; opacity: 0; width: 0; height: 2px; bottom: -3px}
a:hover > u::after,
a:focus > u::after {background-color: #12D0FF; opacity: 1; width: 100%}
p+p {margin-top: 15px}
b, strong {font-weight: 600}
li {list-style: none}
video,
figure,
picture {font-size: 0}
img, video {display: inline-block; width: auto; max-width: 100%; height: auto}
iframe, object, embed {width: 100%; max-width: 100%}
input {font-family: 'Satoshi', sans-serif}
button {cursor: pointer; font-family: inherit; border: 0}
address {font-style: normal}
abbr {text-decoration: none}
[aria-hidden] a {transition: all .4s ease-in-out}
[aria-hidden="true"] a,
[aria-hidden="true"] button,
[aria-hidden="true"] input {visibility: hidden}
[aria-hidden="true"] *,
[aria-hidden="true"] *::before,
[aria-hidden="true"] *::after {background-image: none !important}
[disabled] {cursor: no-drop; filter: grayscale(100%)}

/* GENERAL */
.container {margin: 0 auto; max-width: 700px; width:100%; padding: 0 var(--container-py)}
.embed-responsive {position: relative; display: block; height: 0; padding: 0; overflow: hidden; background: no-repeat center center / 100% auto}
.embed-responsive .embed-responsive-item, .embed-responsive iframe, .embed-responsive embed, .embed-responsive object, .embed-responsive video {position: absolute; top: 0; left: 0; bottom: 0; height: 100%; width: 100%; border: 0}
.embed-responsive-16by9 {padding-bottom: 56.25%}
.link {font-weight: 600; color: #01AAFF; padding-bottom: 2px; border-bottom: 2px solid transparent; font-size: 1.6rem; line-height: 1; display: inline-flex; align-items: center; gap: 5px}
.link:after {content: ''; background: url(../images/icon/arrow-right.svg) no-repeat 0 0 / contain; width: 16px; height: 13px}
.link:hover,
.link:focus {border-bottom-color: currentColor; font-weight: 600; text-decoration: none; filter: brightness(0) saturate(100%) invert(81%) sepia(45%) saturate(6684%) hue-rotate(159deg) brightness(108%) contrast(100%)}
.link.-white {color: #FFF; filter: brightness(0) invert(1)}
.link.-white:hover,
.link.-white:focus {filter: brightness(0) saturate(100%) invert(89%) sepia(27%) saturate(6939%) hue-rotate(355deg) brightness(103%) contrast(101%)}
.link.-black {color: #171717; filter: brightness(0) saturate(100%) invert(9%) sepia(3%) saturate(25%) hue-rotate(318deg) brightness(97%) contrast(100%)}
.link.-black:hover,
.link.-black:focus {border-bottom-color: currentColor; font-weight: 600; text-decoration: none; filter: brightness(0) saturate(100%) invert(81%) sepia(45%) saturate(6684%) hue-rotate(159deg) brightness(108%) contrast(100%)}
.link.-simple {font-weight: 500; color: #171717; border-bottom: 0; text-decoration: underline; text-underline-offset: 4px; font-size: 1.5rem; line-height: 1; display: inline-flex; opacity: .7}
.link.-simple::after {content: none}
.link.-underline {border-bottom-color: inherit}
.button {background: #12D0FF; color: #FFF; font-size: 1.8rem; font-weight: 600; min-height: 50px; padding: 12.4px 20px; text-align: center; border-radius: 6px; text-decoration: none !important; display: inline-flex; justify-content: center; align-items: center; gap: 8px}
.button:hover,
.button:focus {background: #01aaff; color: #FFF !important}
.button.-white {background: #FFF; color: #01AAFF}
.button.-light:not(:focus,:hover) {background: #12D0FF; color: #FFF}
.button.-dark:not(:focus,:hover) {background: #01AAFF; color: #FFF}
.button.-dark:hover,
.button.-dark:focus {outline: 2px solid; outline-offset: -2px}
.button .icon {height: 24px; filter: brightness(0) invert(1)}
.headline {font-size: 3rem; font-weight: 500; line-height: 1; color: #01AAFF; margin-bottom: 17px}

/* HEADER */
.header {position: fixed; top: 0; left: 0; z-index: 10; width: 100%; background: #FFF; box-shadow: 0 3px 6px rgba(0,0,0,.18)}
.header .container {display: grid; grid-template-columns: auto 1fr auto; grid-template-areas: "burger logo search"; align-items: center}
.header .logo {height: 45px; margin: 13.5px 0; position: relative; z-index: 11; display: flex; justify-content: center; grid-area: logo}
.header .logo img {height: 100%}
.header .btn-burger,
.header .btn-search {font-size: 0; background: no-repeat 0 0 / contain; width: 35px; height: 35px; position: relative; z-index: 11}
.header .btn-burger {background-image: url(../images/icon/burger.svg); grid-area: burger}
.header .btn-search {background-image: url(../images/icon/search.svg)}
.header .close {background: none; color: #171717; font-size: 1.8rem; font-weight: 500; line-height: 1; padding: 18px 9px 14px; margin-bottom: 42px; width: 100%; position: relative; z-index: 2; display: flex; justify-content: flex-end; align-items: center; gap: 14px}
.header .close::before {content: ''; width: 15px; height: 15px; background: url(../images/icon/close.svg) no-repeat 0 0 / cover; display: inline-flex}
.header .close::after {content: ''; display: block; position: absolute; bottom: 0; left: 50%; width: calc(100% + 12px); height: 2px; transform: translateX(-50%); border-radius: 2px; background: var(--line)}
.header .nav {transition: all .3s ease-out; background: #FFF; width: 100%; height: calc(100vh - var(--header-h)); height: calc(100dvh - var(--header-h)); display: flex; flex-direction: column}
.header .menu {flex: 1}
.header .secondary {background: #F2F4F8; border-radius: 0 61px 0 0; padding: 40px; font-size: 1.7rem; font-weight: 500; display: flex; flex-direction: column; gap: 34px}
.header .secondary .level-2 {display: none}

.header .search {grid-area: search}
.header .search .content {transform: translateX(-100%); opacity: 0; transition: all .3s ease-out; position: fixed; left: 0; top: var(--header-h); height: calc(100dvh - var(--header-h)); background: #FFF; width: 100%; z-index: 10; padding: 0 40px 40px; box-shadow: inset 0 1px 5px rgba(0,0,0,.25)}
.open-search .search .content {transform: translate(0); opacity: 1}
.header .form {position: relative}
.header .input {border: 0; background: #EFEFF0; border-radius: 5px; color: #353A40; font-size: 1.5rem; padding: 17px 130px 17px 19px; width: 100%}
.header .search .buttons {position: absolute; right: 25px; top: 0; height: 100%; display: flex; flex-direction: row-reverse; gap: 22px}
.header .search .btn-submit {font-size: 0; background: url(../images/icon/magnifier.svg) no-repeat center center / 31px 31px; width: 35px; height: 100%}
.open-search .search .btn-microphone {font-size: 0; background: url(../images/icon/microphone.svg) no-repeat center center / 14px 24px; width: 35px; height: 100%; position: relative}
.header .search .btn-microphone[disabled] {opacity: 0}
.header .btn-keyboard {display: none}
.header .common .title {color: #01AAFF; font-size: 3.6rem; font-weight: 600; line-height: 1; margin: 41px 0 24px -20px; padding: 0 0 8px 20px; background: url(../images/icon/petal.svg) no-repeat 0 bottom / auto 31px}
.header .common .list {font-size: 1.7rem; font-weight: 500; display: flex; flex-direction: column; gap: 14px}
.header .accessibility {display: none}

/* MAIN */
.main {width: 100%; min-height: calc(85dvh - var(--header-h)); overflow-x: hidden; overflow-x: clip}

/* FOOTER */
.footer {padding: 27px 0 100px}
.footer.-no-line hr:first-of-type {opacity: 0}
.footer::after {content: ''; margin-top: 40px; display: block; height: 10px; background: linear-gradient(to right, #01AAFF, #12D0FF)}
.footer .container {position: relative}
.footer hr {border: 0; margin: 28px 0; height: 3px; border-radius: 3px; background: var(--line)}
.footer hr:first-of-type {margin-top: 0}
.footer a {position: relative; z-index: 1; display: inline-flex}
.footer a:not(.button):hover:before,
.footer a:not(.button):focus:before {content: ''; display: block; width: 33px; height: 15px; position: absolute; transform: translate(-32%, 54%); background: url(../images/icon/petal.svg) no-repeat 0 0 / contain; z-index: -1}
.footer .-blue {color: #01AAFF}
.sitemap {background: #FFF; border-radius: 10px; margin-bottom: 30px}
.sitemap .toggle {padding: 15.5px; font-size: 2.2rem; font-weight: 600; color: #01AAFF; background: none; border: 0; width: 100%; display: flex; justify-content: center; align-items: center; gap: 19px}
.sitemap .toggle:after {content: ''; background: url(../images/icon/arrow-down.svg) no-repeat center center / contain; width: 22px; height: 14px; transition: all 1s ease-in-out}
.sitemap .map {overflow: hidden; max-height: 0; transition: max-height .5s ease-in-out}
.sitemap .nav {padding: 11px 15px 37px; display: grid}
.sitemap.-open .toggle::after {transform: scale(-1,-1)}
.sitemap.-open .map {max-height: 3000px}
.sitemap .nav ul,
.sitemap .nav li {display: flex; flex-direction: column}
.sitemap .nav > ul {padding: 20px 0}
.sitemap .nav > ul:not(:first-of-type) {border-top: 2px solid; border-image: var(--line) 1}
.sitemap .level-1 {gap: 10px}
.sitemap .level-1 > li {font-size: 1.8rem; font-weight: 600; gap: 20px}
.sitemap .level-1 > li > a:hover:before,
.sitemap .level-1 > li > a:focus:before {height: 19px; width: 42px}
.sitemap .level-2 {gap: 15px}
.sitemap .level-2 > li {font-size: 1.6rem; font-weight: 600; gap: 7px}
.sitemap .level-3 {gap: 5px}
.sitemap .level-3 > li {font-size: 1.4rem; font-weight: 500}
.access {background: #01AAFF; border-radius: 0 0 10px 10px; color: #FFF; text-align: center; position: relative; padding: 17px 20px 0; display: flex; flex-direction: column}
.access::before {content: ''; position: absolute; left: 0; bottom: 0; width: 205px; height: 136px; border-radius: 0 0 10px 10px; background: url(../images/illustration/petals-left.svg)}
.access > * {z-index: 1}
.access .title {font-size: 2rem; font-weight: 600; line-height: normal; letter-spacing: 0; margin-bottom: 18px}
.channels {display: flex; flex-direction: column; gap: 20px}
.channels .-box {background: #FFF; border-radius: 10px; padding: 30px; display: flex; flex-direction: column}
.channels .-box nav {display: flex; flex: 1}
.channels .title {font-size: 2.2rem; color: #01AAFF; margin-bottom: 22px; text-align: center}
.channels a:not(.button):hover:before,
.channels a:not(.button):focus:before {width: 41px; height: 16px; transform: translate(-23%, 71%)}
.contacts {grid-area: contacts}
.contacts ul {font-size: 1.8rem; display: flex; flex-direction: column; justify-content: space-between; gap: 20px}
.contacts .icon {font-size: inherit; display: flex; gap: 4px}
.contacts .icon img {max-width: 22px; filter: brightness(0) saturate(100%) invert(37%) sepia(86%) saturate(915%) hue-rotate(77deg) brightness(99%) contrast(106%)}
.contacts a {display: block; font-size: 2.1rem; font-weight: 600; color: #01AAFF}
.browse {grid-area: browse}
.browse ul {font-size: 1.8rem; font-weight: 500; display: flex; flex-direction: column; gap: 15px}
.browse .button {width: 100%}
.assistance {padding: 21px 0 0; font-size: 1.9rem; text-align: center; grid-area: assistance}
.assistance p {margin-top: 14px}
.assistance .sani {margin-top: 25px}
.assistance .sani p {margin-bottom: 25px; color: #01AAFF; font-size: 1.4rem; font-weight: 600}
.assistance .sani a::before {content: none !important}
.assistance .figure img {max-width: 236px}
.assistance .description .buttons {max-width: 313px; margin: 20px auto 0; display: flex; flex-direction: column; gap: 20px}
.assistance .description p:not(:first-child) {margin-top: 0; font-size: 1.8rem}
.social {font-size: 0; padding-inline: 11px; display: flex; justify-content: center; gap: 20px}
.social a::before{display: none !important}
.social a:hover,
.social a:focus {outline: 0; transform: scale(1.06); filter: brightness(0) saturate(100%) invert(48%) sepia(78%) saturate(2199%) hue-rotate(172deg) brightness(101%) contrast(105%)}
.footer .form {margin-top: 29px; position: relative; display: flex}
.footer .form .input {border: 2px solid #B9B8B8; border-radius: 6px; font-size: 1.5rem; color: #565656; letter-spacing: -0.3px; padding: 15px 90px 15px 20px; flex: 1}
.footer .form .buttons {background: #F2F4F8; border-left: 2px solid #B9B8B8; border-radius: 0 6px 6px 0; padding: 0 6px; position: absolute; top: 2px; right: 2px; z-index: 1; height: calc(100% - 4px); display: flex}
.footer .form button {font-size: 0; filter: brightness(0) saturate(100%) invert(0%) sepia(8%) saturate(445%) hue-rotate(317deg) brightness(102%) contrast(82%)}
.footer .btn-submit {font-size: 0; background: url(../images/icon/magnifier.svg) no-repeat center center / 24px auto; width: 39px; height: 100%}
.footer .btn-microphone {font-size: 0; background: url(../images/icon/microphone.svg) no-repeat center center / 13px auto; width: 28px; height: 100%}
.footer .form .input:focus,
.footer .form .input:hover,
.footer .form .input:hover + .buttons {border-color: #017CEB; outline: none}
.footer .form .input:hover + .buttons button {filter: brightness(0) saturate(100%) invert(36%) sepia(47%) saturate(4479%) hue-rotate(192deg) brightness(95%) contrast(103%)}
.copyright {margin: 28px 0; display: flex; flex-wrap: wrap; justify-content: center; gap: 10px}
.copyright a {font-size: 1.3rem; outline: 0; display: flex; align-items: center; gap: 10px}
.copyright a:hover,
.copyright a:focus {color: #017CEB; font-weight: 600}
.footer .copyright a::before,
.footer .copyright a:hover::before,
.footer .copyright a:focus::before {content: ''; width: 6px; height: 6px; border-radius: 50%; background: #017CEB; position: static; transform: none; display: inline-flex}
.footer .copyright a:first-of-type:before {display: none}

/* FLOAT BUTTON */
.float-button {display: none; background-image: url(../images/illustration/sani-sabesp-ajuda.svg)}

/* KEYBOARD */
.keyboard {max-width: 740px; width: 100%; max-height: 0; overflow: hidden; transition: all .3s ease-in-out; position: fixed; bottom: 0; z-index: 2; left: 50%; transform: translate(-50%, 0); font-size: 1.4rem}

/* COOKIE CONSENT */
.cookie-consent {border: 0; border-radius: 10px; background: #12D0FF; box-shadow: 0 4px 4px rgba(0,0,0,.25); padding: 10px; width: 94%; max-width: 1100px; position: fixed; left: 50%; bottom: 0; z-index: 11; transform: translate(-50%, 200px); transition: all .5s ease-in-out; display: flex; flex-direction: column; gap: 10px}
.cookie-consent[open] {transform: translate(-50%, 66.5vh)}
.cookie-consent .text {font-size: 1.4rem; font-weight: 500; color: #000}
.cookie-consent a {text-decoration: underline; text-decoration-thickness: 2px; text-underline-offset: 3px; color: inherit}
.cookie-consent a:hover,
.cookie-consent a:focus {color: #fff}
.cookie-consent strong {font-weight: 900}
.cookie-consent .close {width: 100%}

/* COOKIE */
.cookie-container[open] {width: 100%; position: fixed; bottom: 20px; z-index: 11; display: flex; justify-content: center; background-color: transparent; border: none; padding: 5px 0; margin: 0 auto;}
.cookie-container .cookie-box {border: 0; border-radius: 10px; background: #12D0FF; box-shadow: 0 4px 4px rgba(0,0,0,.25); padding: 10px; width: 94%; max-width: 1100px; transition: all .5s ease-in-out; display: flex; flex-direction: column; gap: 10px; position: fixed; bottom: 25px; left: 50%; transform: translate(-50%, 0);}
.cookie-container .cookie-box .text {font-size: 1.4rem; font-weight: 500; color: #000}
.cookie-container .cookie-box a {text-decoration: underline; text-decoration-thickness: 2px; text-underline-offset: 3px; color: inherit}
.cookie-container .cookie-box a:hover,
.cookie-container .cookie-box a:focus {color: #fff}
.cookie-container .cookie-box strong {font-weight: 900}
.cookie-container .cookie-box .close {width: 100%}

@media (max-width: 1199px) {
	/* HEADER */
	/* .open-nav .header .nav {transform: translateX(0); opacity: 1} */
	.header .nav {padding: 0 40px; overflow-y: auto; overflow-x: hidden; opacity: 0; transform: translateX(-100%); position: fixed; left: 0; top: var(--header-h); height: calc(100% - var(--header-h))}
	.header::before {content: ''; z-index: 4; position: absolute; left: 0; top: 0; height: 100%; box-shadow: 0 1px 5px rgba(0,0,0,.25); width: 100%}
	.header .back {background: none; color: #171717; font-size: 1.8rem; font-weight: 500; line-height: 1; padding: 18px 9px 14px; margin-left: -12px; position: absolute; width: 40%; z-index: 3; opacity: 0; transition: all .5s ease-in-out; display: flex; align-items: center; gap: 14px}
	.header .back::before {content: ''; width: 15px; height: 9px; background: url(../images/icon/arrow-down.svg) no-repeat center center / 100%; transform: rotate(90deg); filter: brightness(0) invert(0); display: inline-block}
	.header .breadcrumbs {opacity: 0; font-size: 1.3rem; position: absolute; top: 68px; z-index: 2; overflow-x: auto; width: calc(100% - 82px); display: flex}
	.header .breadcrumbs li {display: inline-flex; align-items: center}
	.header .breadcrumbs li:not(:first-child)::before {content: '>'; font-size: 1rem; margin: 0 4px; display: inline-flex; align-items: center}
	.header .breadcrumbs a {color: #8B8B8B; min-width: max-content}
	.header .breadcrumbs li:last-of-type a {color: #01AAFF; font-weight: 600}
	.header .menu {display: flex; flex-direction: column; gap: 37px}
	.header .menu a {font-size: 1.8rem; font-weight: 500; color: #171717; display: flex; align-items: center; justify-content: space-between}
	.header .menu ul {display: flex; flex-direction: column; gap: 37px}
	.header .menu .-sub > a::after {content: ''; width: 14px; height: 9px; background: url(../images/icon/arrow-down.svg) no-repeat center center / 100%; transform: rotate(-90deg); filter: brightness(0) invert(0); display: inline-block}
	.header .menu {margin: 10px 0 52px}
	.header .level-1 ul {padding: 0 40px 30px; border-right-width: 10px; border-right-style: solid; position: absolute; top: 0; left: 0; transform: translateX(-100%); transition: all .5s ease-in-out; width: 100vw; min-height: 100%; background: #FFF}
	.header .level-1 ul::before {content: var(--title, ''); color: #01aaff; font-size: 3.6rem; font-weight: 600; line-height: 1; margin: 103px 0 -9px -20px; padding: 0 0 8px 20px; background: url(../images/icon/petal.svg) no-repeat 0 bottom / auto 31px}
	.header .level-2 {border-right-color: #01aaff}
	.header .level-3 {border-right-color: #129180}
	.header .level-4 {border-right-color: #fab180}
	.header .level-5 {border-right-color: #ffc601}
	.header .-open > ul {transform: translateX(0); z-index: 1}

	.open-nav {overflow-y: hidden}
	/* .open-nav .float-nav {z-index: 0} */
	.open-subnav .secondary {display: none}
	.open-subnav .header .menu {overflow-y: auto; height: calc(100vh - var(--header-h))}

	.open-subnav .back,
	.open-subnav .breadcrumbs {opacity: 1}
	.header .secondary {margin: 0 -40px}
	.header .secondary .button.-white {padding: 0; background: none; min-height: 0; font-weight: 500; color: inherit; font-size: inherit}

	/* FLOAT NAV */
	.float-nav {background: #FFF; z-index: 9; position: fixed; bottom: 0; left: 0; width: 100%; box-shadow: 0 -4px 12px rgba(0,0,0,.31)}
	.float-nav::before{content: ''; display: block; height: 2px; background: var(--line)}
	.float-nav .close {background: #fff; color: #171717; transition: all .5s ease-in-out; font-size: 1.8rem; font-weight: 500; line-height: 1; padding: 18px 9px 14px; width: calc(100% - 80px); transform: translateX(-200%); position: fixed; top: var(--header-h); left: 50%; z-index: 2; display: flex; justify-content: flex-end; align-items: center; gap: 14px}
	.float-nav .close::before {content: ''; width: 15px; height: 15px; background: url(../images/icon/close.svg) no-repeat 0 0 / cover; display: inline-flex}
	.float-nav .close::after {content: ''; display: block; position: absolute; bottom: 0; left: 50%; width: calc(100% + 12px); height: 2px; transform: translateX(-50%); border-radius: 2px; background: var(--line)}
	.float-nav .menu {padding: 15px var(--container-py) 13px; display: grid; grid-auto-flow: column dense; grid-template-columns: 1fr 1fr 1fr; grid-template-rows: auto}
	.float-nav .item {position: relative; display: flex; justify-content: center}
	.float-nav .item:not(:first-child):before {content: ''; position: absolute; top: 50%; left: -1px; transform: translateY(-50%); width: 2px; height: 90%; background: #01AAFF; border-radius: 2px}
	.float-nav .menu > li > a {text-align: center; color: #00abff; font-size: 1.516rem; font-weight: 600; line-height: 16px; max-width: 91px; z-index: 1; display: inline-block}
	.float-nav .menu > li > a:hover::before,
	.float-nav .menu > .-open > a::before {content: ''; height: 6px; width: 70px; background: currentColor; border-radius: 6px; top: -19px; position: absolute; left: 50%; transform: translateX(-50%)}
	.float-nav .icon {margin-bottom: 2px}
	.float-nav .icon img {width: auto; max-height: 38px; filter: brightness(0) saturate(100%) invert(50%) sepia(70%) saturate(2740%) hue-rotate(172deg) brightness(102%) contrast(105%)}
	.float-nav .level-1 ul {padding: 0 40px 40px; overflow-y: auto; border-right-width: 10px; border-right-style: solid; position: fixed; top: var(--header-h); left: 0; transform: translateX(-100%); transition: all .5s ease-in-out; width: 100vw; height: calc(100% - var(--header-h) - 110px); background: #FFF; display: flex; flex-direction: column; gap: 37px}
	.float-nav .level-1 ul::before {content: var(--title); color: #01aaff; font-size: 3.6rem; font-weight: 600; line-height: 1; margin: 103px 0 -9px -20px; padding: 0 0 8px 20px; background: url(../images/icon/petal.svg) no-repeat 0 bottom / auto 31px}
	.float-nav .level-2 {border-right-color: #01aaff}
	.float-nav .level-2 a {font-size: 1.8rem; font-weight: 500; color: #171717; display: flex; align-items: center; justify-content: space-between}
	.open-float-nav .float-nav .close {transform: translateX(-50%)}
	.open-float-nav .float-nav .level-2 {transform: translateX(0)}
}

@media (min-width: 430px) {
	:root {--container-py: 30px}
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
	/* FOOTER */
	.sitemap .nav {padding-inline: 20px; grid-auto-columns: 1fr; grid-template-columns: 1fr 1fr; grid-template-rows: auto auto auto; gap: 0 10%}
	.sitemap .nav > ul:nth-of-type(2) {border-top: 0}
	.channels {display: grid; grid-template-columns: 1fr 1fr; gap: 2% 5%; grid-template-areas: "contacts browse" "assistance assistance"}
	.channels .-box {padding-inline: 20px}
	.assistance {padding-bottom: 25px}

	/* COOKIE CONSENT */
	.cookie-container .cookie-box {padding: 11px 12px 11px 20px; width: 90%; flex-direction: row; justify-content: space-between; align-items: center; gap: 20px}
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
	/* GENERAL */
	.container {max-width: 970px}

	/* FOOTER */
	.sitemap .nav {grid-template-columns: 1fr 1fr 1fr; grid-template-rows: auto auto}
	.sitemap .nav > ul:nth-of-type(3) {border-top: 0}
	.assistance > .title {width: 50%}
	.assistance .content {display: grid; gap: 0 5%; grid-auto-flow: column; grid-auto-rows: 1fr; grid-template-columns: 1fr 1fr; grid-template-rows: auto auto 1fr; grid-template-areas: "description sani" "button sani" "social sani"; align-items: start}
	.assistance .description {grid-area: description}
	.assistance .description .buttons {margin-bottom: 28px}
	.assistance p:first-of-type {margin-top: 0}
	.assistance .sani {margin: -33px 30px 0 0; grid-area: sani}
	.assistance .title {margin-bottom: 2px}
	.assistance .sani p {margin-bottom: 7px}
	.assistance .figure img {position: relative; right: 0}
	.assistance .social {padding: 5px 0 0; gap: 12px; grid-area: social}
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
	:root {
		--header-h: 110px;
	}

	/* GENERAL */
	.container {max-width: 1360px}
	.link {font-size: 1.8rem}
	.headline {margin-bottom: 23px}

	/* HEADER */
	.header .logo {height: 78px; margin: 16px 0}
	.header .container {position: relative; display: grid; grid-template-columns: auto 1fr auto auto; grid-template-rows: auto; grid-template-areas: "logo nav search accessibility"; align-items: flex-end}
	.header .nav {height: 100%; padding-bottom: 21px; box-shadow: none; flex-direction: column-reverse; align-items: flex-end; justify-content: space-between; grid-area: nav}
	.header .nav a {position: relative; z-index: 1}
	.header .btn-burger,
	.header .nav .back,
	.header .nav .close {display: none}
	.header .menu {transition: all .4s ease-in-out; font-size: 1.8rem; font-weight: 500; display: flex; flex: none; gap: 40px}
	.header .secondary {border-radius: 999px 0 0 999px; font-size: 1.4rem; font-weight: 600; padding: 0; position: absolute; top: 0; right: 30px; flex-direction: row; align-items: center; justify-content: flex-end; gap: 24px}
	.header .secondary:after {content: ''; position: absolute; left: 100%; background: #F2F4F8; height: 100%; width: 50vw}
	.header .secondary .button {border-radius: 999px; font-size: 1.6rem; padding: 7.8px 30px; min-height: 38px}
	.header .secondary .button.-white {position: relative; font-size: 1.4rem; margin-right: -30px}
	.header .secondary .button.-white:after {content: ''; position: absolute; top: 0; left: 90%; transform: none; background: #FFF; height: 100%; width: 50vw}
	.header .btn-search {width: 32px; height: 32px}
	.header .menu ul {display: none}
	.header .menu > li.-sub > a:hover::after,
	.header .secondary > li.-sub > a:hover::after {content: ''; width: 300%; height: 150%; transform: translateX(-50%); position: absolute; left: 50%; top: 95%}
	.header .columns {background: #FFF; position: absolute; top: 100%; left: 50%; transform: translateX(-50%); max-width: 1360px; width: 100%; padding: 20px 30px; display: none; grid-template-columns: repeat(3, 1fr); gap: 0 80px}
	.header .columns::before,
	.header .columns::after {content: ''; z-index: -1; position: absolute; top: 0; left: 50%; transform: translateX(-50%); width: 100vw; display: block}
	.header .columns::before {height: 100%; background: #FFF; box-shadow: 0 4px 4px rgba(0,0,0,.25)}
	.header .columns::after {height: 2px; background: var(--line); box-shadow: 0 3px 6px rgba(0,0,0,.18)}
	.header .-open .columns {display: grid}
	.header .columns > ul {position: relative; padding: 8px 0; min-height: 320px; display: flex; flex-direction: column}
	.header .columns > ul:not(:first-of-type)::before {content: ''; width: 2px; height: 100%; border-radius: 2px; background: #12D0FF; position: absolute; left: -39px; top: 0}
	.header .secondary .level-2 a,
	.header .columns .level-2 a {font-weight: 600; display: inline-flex; align-items: center; gap: 7px}
	.header .secondary .level-2 > li {display: block}
	.header .secondary .level-2 > li:last-of-type {margin-bottom: 5px}
	.header .secondary .level-2 a {min-width: max-content}
 	.header .secondary .level-2 a::after{filter: brightness(0) invert(0)}
	.header .columns .level-2 > li > a:not([href^="javascript"]):hover,
	.header .columns .level-2 > li > a:not([href^="javascript"]):focus {text-decoration: underline; text-underline-offset: 6px}
	.header .columns .level-2 > li > a:not([href^="javascript"]):after {content: ''; min-width: 15px; min-height: 9px; background: url(../images/icon/arrow-down.svg) no-repeat center center / 100% auto; transform: rotate(-90deg); display: inline-flex}
	.header .columns .level-2 > li > a[target="_blank"]:not([href^="javascript"]):after {background: url(../images/icon/link.svg) no-repeat center center / 100% auto; width: 13px; height: 14px; transform: none}
	.header .level-2 > li > a:not([href^="javascript"]):after,
	.header .level-3 > li > a:not([href^="javascript"]):after,
	.header .level-4 > .-sub > a:not([href^="javascript"]):after {content: ''; width: 13px; height: 7px; background: url(../images/icon/arrow-down.svg) no-repeat center center / 100% auto; transform: rotate(-90deg); display: inline-flex}
	.header .level-3 a::after {filter: brightness(0) invert(0)}
	.header .level-4 > .-sub > a:not([href^="javascript"]):after {transform: rotate(-360deg)}
	.header .secondary .level-2 a::after {transform: rotate(270deg)}
	.header .columns .level-2 > li > a {font-size: 2.4rem; font-weight: 600; color: #01AAFF}
	.header .columns .level-2 > li > a:after {width: 15px; height: 9px; filter: brightness(0) saturate(100%) invert(43%) sepia(52%) saturate(2164%) hue-rotate(176deg) brightness(107%) contrast(104%)}
	.header .columns .level-2 li + li::before,
	.header .secondary .level-2 li + li::before {content: ''; display: block; border-radius: 999px; height: 2px; margin: 14px 0; background: var(--line)}
	.header .columns .level-2 > li + li::before {margin: 32px 0}
	.header .secondary .level-2 .-half::before {opacity: 0; margin-top: -2px}
	.header .columns .level-3 {margin-top: 14px}
	.header .columns .level-3 a[target="_blank"]:after {content: ''; background: url(../images/icon/link.svg) no-repeat center center / 100% auto; width: 12px; height: 12px; transform: none}
	.header .columns .level-4 {padding: 6px 0 0 10px; gap: 4px}
	.header .columns .level-4 a {font-size: 1.6rem; font-weight: 500}
	.header .columns .level-4 a[target="_blank"]:after {content: ''; background: url(../images/icon/link.svg) no-repeat center center / 100% auto; width: 12px; height: 12px; transform: none}
 	.header .columns .level-4 li + li::before {content: none}
	.header .columns .level-4 li:hover > a {font-weight: 600; color: #01AAFF}
	.header .columns .level-4 > li:hover > a::after{transform: rotate(180deg); filter: brightness(0) saturate(100%) invert(43%) sepia(52%) saturate(2164%) hue-rotate(176deg) brightness(107%) contrast(104%)}
	.header .columns .level-4 li:hover .level-5,
	.header .columns .level-4 li a:hover + .level-5 {display: flex}
	.header .columns .level-5 {padding: 1px 0 0 10px; flex-direction: column; gap: 4px}
	.header .columns .level-5 a {font-size: 1.4rem}
	/*.header .columns [data-open="true"] > a:after {display: none !important}*/
	.header .columns [data-open="true"] > ul {display: flex; flex-direction: column}
	.header .columns a:not([href^="javascript"]):focus,
	.header .columns a:not([href^="javascript"]):hover,
	.header .secondary a:not(.button):hover,
	.header .secondary a:not(.button):focus,
	.header .level-1 > .-open > a,
	.header .secondary > .-open > a {color: #01AAFF; font-weight: 600; text-decoration: none !important;}
	.header .secondary > li > a:not(.button):hover,
	.header .secondary > li > a:not(.button):focus,
	.header .secondary > li > a.-white:hover,
	.header .secondary > li > a.-white:focus {color: #017CEB !important; font-weight: 600}
	.header .nav a:not(.button):hover:after,
	.header .nav a:not(.button):focus:after{filter: brightness(0) saturate(100%) invert(43%) sepia(97%) saturate(1624%) hue-rotate(174deg) brightness(105%) contrast(103%)}
	.header .secondary > li > a:not(.button):hover:before,
	.header .secondary > li > a:not(.button):focus:before,
	.header .secondary > li > a.-white:hover:before,
	.header .secondary > li > a.-white:focus:before,
	.header .secondary > .-open > a:before,
	.header .level-1 > .-open > a:before {content: ''; display: block; width: 33px; height: 15px; position: absolute; transform: translate(-32%, 54%); background: url(../images/icon/petal.svg) no-repeat 0 0 / contain; z-index: -1}
	.header .level-1 > .-open > a:before {width: 78px; height: 36px; transform: translate(-10%, -10%)}

	.header .level-1 .selected > a {color: #01AAFF; font-weight: 600; text-decoration: underline; text-underline-offset: 4px;};
	
	.header .secondary > li > a.-white:hover:before {transform: translate(-51%, 36%)}
	.header .secondary .level-2 {font-size: 1.6rem; display: none; position: absolute; z-index: 12; top: 100%; right: 0; background: #FFF; box-shadow: 0 4px 4px rgba(0,0,0,.25); padding: 20px 40px; border-radius: 0 0 20px 20px; columns: 2; column-gap: 60px; column-rule: 2px solid #12D0FF; column-span: all}
	.header .secondary > .-open:after {content: ''; z-index: 2; position: absolute; left: 20px; top: calc(100% - 2px); width: 55vw; height: 2px; background: var(--line)}
	.header .secondary > .-open .level-2 {display: block}
	.header .search {margin: 0 30px 17px; position: relative; grid-area: search}
	.header .search .content {transform: translate(0, -50%); opacity: 0; position: absolute; z-index: 11; left: auto; top: 50%; right: -1px; width: 0; height: auto; padding: 0; background: none; box-shadow: none; overflow: hidden}
	.header .search .input {background: #f2f4f8; border: 1px solid #D0D0D0; border-radius: 999px; padding: 10.5px 130px 10.5px 34px; outline: none}
	.header .search .buttons {right: -48px; flex-direction: row; gap: 8px}
	.header .search .buttons > * {font-size: 0; width: 40px; height: 100%}
	.header .search .btn-microphone {background-size: 15px 27px; filter: brightness(0) saturate(100%) invert(34%) sepia(46%) saturate(3770%) hue-rotate(193deg) brightness(95%) contrast(100%)}
	.header .search .close,
	.header .search .common,
	.header .breadcrumbs {display: none}
	.open-search .search .content {width: 697px; overflow: initial; opacity: 1}
	.open-search .search .btn-keyboard {display: inline-block; background: url(../images/icon/keyboard.svg) no-repeat center center / 31px 18px; width: 55px}
	.open-search .search .btn-submit {background: url(../images/icon/search2.svg) no-repeat 0 0 / cover; width: 44px}
	.open-search .header .menu,
	.open-search .header .accessibility {opacity: 0; z-index: -1}
	.header .accessibility {position: relative; margin-left: -3px; width: 118px; transition: all .4s ease-in-out; display: flex; grid-area: accessibility}
	.header .accessibility button {background: transparent no-repeat center center / 100% auto}
	.header .accessibility .content {margin-bottom: 23px; overflow: hidden; transition: all .3s ease-in-out; transform: translateY(0); display: flex; gap: 15px}
	.header .accessibility .toggle {font-size: 1.5rem; font-weight: 600; display: flex; align-items: center; gap: 6px}
	.header .accessibility .toggle::before,
	.header .accessibility .back::after {content: ''; width: 12px; height: 7px; background: url(../images/icon/arrow-down.svg) no-repeat center center / 100%; transform: rotate(90deg); filter: brightness(0) invert(0); display: inline-block}
	.header .accessibility .back::after {transform: rotate(-90deg)}
	.header .accessibility .back {font-size: 0; width: 12px; height: 100%; filter: brightness(0) invert(0); display: inline-block; display: flex; justify-content: center; align-items: center}
	.header .accessibility .tools {display: flex; align-items: center; gap: 12px}
	.header .accessibility .tools button {width: 18px; height: 20px; font-size: 0}
	.open-accessibility .accessibility .content {transform: translateX(calc(-100% + 118px)); overflow: initial}
	.open-accessibility .accessibility .toggle::before {display: none}
	.open-accessibility .accessibility .tools .print {background-image: url(../images/icon/printer.svg)}
	.open-accessibility .accessibility .tools .contrast {background-image: url(../images/icon/barcode.svg); width: 17px}
	.open-accessibility .accessibility .tools .font-bigger {background-image: url(../images/icon/font-bigger.svg); width: 26px}
	.open-accessibility .accessibility .tools .font-smaller {background-image: url(../images/icon/font-smaller.svg); width: 20px}
	.open-accessibility .accessibility .tools .links {background-image: url(../images/icon/links.svg)}
	.open-accessibility .accessibility .tools .font-readable {background-image: url(../images/icon/readable.svg); width: 14px}
	.open-accessibility .accessibility .tools .high-contrast {background-image: url(../images/icon/contrast.svg)}
	.open-accessibility .accessibility .tools .negative-contrast {background-image: url(../images/icon/eye.svg); width: 23px}
	.open-accessibility .accessibility .tools .reset {background-image: url(../images/icon/reset.svg)}
	.open-accessibility .header .menu,
	.open-accessibility .header .search {opacity: 0; z-index: -1}

	/* FOOTER */
	.footer {padding-bottom: 0}
	.sitemap {margin-bottom: 40px}
	.sitemap .nav {padding-inline: 0; grid-template-columns: 1fr 1fr 1fr 1.5fr max-content; grid-template-rows: auto; gap: 0}
	.sitemap .nav > ul {padding: 0 40px}
	.sitemap .nav > ul:first-of-type {padding-left: 33px}
	.sitemap .nav > ul:last-of-type {padding-right: 33px}
	.sitemap .nav > ul:not(:first-of-type) {border-top: 0; border-left: 2px solid #01AAFF; border-radius: 2px}
	.sitemap .nav .col-2 {flex-direction: row; flex-wrap: wrap}
	.sitemap .nav .col-2 > li {width: calc(50% - 8px)}
	.access {margin-top: 40px; padding: 0 30px; flex-direction: row; justify-content: center; align-items: center; gap: 42px}
	.access::before {left: auto; right: 0; width: 395px; height: 166px; background: url(../images/illustration/petals-right.svg)}
	.access .title {font-size: 3.5rem; margin-bottom: 0; order: 2}
	.access .figure {margin: -86px -33px 0 -140px; order: 1}
	.access .figure img {width: 209px}
	.access .button {order: 3}
	.channels {grid-template-columns: minmax(auto, 285px) minmax(auto, 245px) 1fr; grid-template-rows: 1fr; gap: 0 2.35%; grid-template-areas: "contacts browse assistance"}
	.channels .title {text-align: start}
	.channels .-box {max-width: 310px}
	.contacts a {font-size: 2.2rem; font-weight: 500}
	.browse .button {width: auto}
	.assistance {text-align: start; padding: 30px 0 0}
	.assistance .social {justify-content: flex-start}
	.assistance .content {grid-template-columns: 1fr auto}
	.assistance .description {max-width: 313px}
	.footer .form {margin-top: 28px}
	.footer .form .input {font-size: 1.64rem}
	.copyright {margin: 50px 0}

	/* FLOAT NAV */
	.float-nav {display: none}
	.float-nav .icon img{display: none}

	/* FLOAT BUTTON */
	.float-button {display: block; transition: width .2s, height .5s ease-in-out; z-index: 2; position: fixed; bottom: -1px; right: 12px; width: 112px; height: 128px; background: url(../images/illustration/sani-sabesp.svg) no-repeat right top / auto 128px; font-size: 0}
	.float-button:hover {width: 332px; bottom: 7px; right: 9px; background-image: url(../images/illustration/sani-sabesp-ajuda.svg); filter: drop-shadow(0 14px 22px rgba(0,0,0,.1))}
	.float-button::before{display: none!important}

	/* KEYBOARD */
	.open-keyboard .keyboard {transform: translate(-50%, -5%); max-height: 500px}

	/* COOKIE CONSENT */
	.cookie-container .cookie-box {width: 75%; left: 45%}
}

/* XX-Large devices (larger desktops, 1400px and up)*/
@media (min-width: 1400px) {
	/* GENERAL */
	.container {max-width: 1360px}

	/* HEADER */
	.header .menu {gap: 60px}
	.header .secondary {gap: 44px}
	.header .search {margin: 0 50px 17px}

	/* FOOTER */
	.channels {grid-template-columns: minmax(auto, 310px) minmax(auto, 310px) 1fr}

	/* COOKIE CONSENT */
	.cookie-container .cookie-box {left: 50%}
}

/* PRINT */
@media print {
	.header {position: absolute}
	.float-nav {display: none}
}

body.-listening .buttons .btn-microphone {filter: brightness(0) saturate(100%) invert(40%) sepia(88%) saturate(1214%) hue-rotate(329deg) brightness(101%) contrast(105%)}
body.-listening .buttons .btn-microphone:before {content: ''; position: absolute; top: calc(50% - 25px); left: calc(50% - 25px); width: 50px; height: 50px; border-radius: 50%; background-color: #ff5252; animation: pulse 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite}
@keyframes pulse {
	0% {transform: scale(.33)}
	80%, 100% {opacity: 0}
}

/* ACCESSIBILITY */
body.links-underline a,
body.links-underline a * {text-decoration: underline !important}
body.font-readable * {font-family: Arial, Helvetica, Verdana, sans-serif !important}
body.contraste {filter: grayscale(100%)}


@font-face {
	font-family: 'Satoshi';
	src: url(../fonts/Satoshi-Regular.woff2) format('woff2');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Satoshi';
	src: url(../fonts/Satoshi-Medium.woff2) format('woff2');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Satoshi';
	src: url(../fonts/Satoshi-Bold.woff2) format('woff2');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Satoshi';
	src: url(../fonts/Satoshi-Black.woff2) format('woff2');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

.circle-buttons:hover .-over .item:after {background: #FFF}
.circle-buttons > li:hover {z-index: 3}
.circle-buttons > li:hover .item {text-decoration: none; outline: none; color: #01AAFF; transform: translateY(-10%)}
.circle-buttons > li:hover .item::before {content: ''}
.circle-buttons > li:hover img {filter: none}
.circle-buttons li:hover .share {opacity: 1}
.circle-buttons li:hover .item::after {background: rgb(255 255 255)}
