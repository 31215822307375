/* BANNER */
.banner {border-radius: 10px; box-shadow: 0 4px 4px rgba(0,0,0,.17); margin-bottom: 19px; position: relative; display: inline-flex}
.banner img {border-radius: 10px}
.banner.petals::before {content: ''; position: absolute; left: 100%; top: 50%; background: url(../images/illustration/petals-2.svg) no-repeat right 0 / cover; max-width: 263px; max-height: 301px; width: 20.24%; height: 123.37%; transform: translate(-30%, -67.1%)}
.banner.petals-2::before {content: ''; position: absolute; left: 100%; top: 0; background: url(../images/illustration/petals-4.svg) no-repeat left 0 / contain; max-width: 146px; max-height: 203px; width: 11.24%; height: 83.2%; transform: translate(-25.5%, -38%)}

/* IMAGE ALIGN */
.align-right {width: 100%; float:right; text-align: center; margin:0 0 1.8rem 5rem}
.align-left {width: 100%; float:left; text-align: center; margin:0 5rem 1.8rem 0}
.align-center {display: block; text-align: center; margin-left: auto; margin-right: auto}

/* TEXT ALIGN */
.txt-right {text-align: right !important}
.txt-left {text-align: left !important}
.txt-center {text-align: center !important}

/* PAGINATION */
.pagination{display: block}
.main .pagination .menu {margin: 43px 0 33px; display: flex; flex-direction: row; justify-content: center; gap: 12px}
.pagination a {text-decoration: none !important; border-radius: 50%; font-size: 2.4rem; font-family: Satoshi, sans-serif; font-weight: 500; aspect-ratio: 1; min-width: 35px; display: flex; justify-content: center; align-items: center}
.pagination .-active {position: relative}
.pagination .-active::before {content: ''; display: block; background: url(../images/illustration/isolation-mode.svg) no-repeat 0 0 / cover; width: 42px; height: 42px; position: absolute; bottom: 0; right: 0}
.pagination .-active a {position: relative; font-weight: 900; color: #01AAFF; background: #FFF; box-shadow: 0 2px 4px rgba(0,0,0,.25)}
.pagination .-prev a,
.pagination .-next a {font-size: 0; display: flex; justify-content: center; align-items: center}
.pagination .-prev a:after,
.pagination .-next a:after {content: ''; width: 15px; height: 9px; background: url(../images/icon/arrow-down.svg) no-repeat center center / cover; display: block; filter: brightness(0)}
.pagination .-prev a:after {transform: rotate(90deg)}
.pagination .-next a:after {transform: rotate(-90deg)}
.pagination .-disabled a{pointer-events: none; cursor: no-drop}
.pagination .-disabled a:after {opacity: .5}
.pagination a:hover:after,
.pagination a:focus:after {filter: brightness(0) saturate(100%) invert(55%) sepia(25%) saturate(6218%) hue-rotate(169deg) brightness(100%) contrast(101%)}
.pagination .-disabled > a:hover:after,
.pagination .-disabled > a:focus:after {filter: brightness(0)}

/* MORE */
.more {padding: 35px 0 30px}
.more.-white {background: #FFF}
.more .columns {display: flex; flex-direction: column; gap: 45px}
.more .items {display: flex; flex-direction: column; gap: 21px}
.more .item {position: relative; padding: 17px 0 23px; display: flex; justify-content: space-between; gap: 15px}
.more .item::after {content: ''; display: block; position: absolute; left: 0; bottom: 0; width: 100%; height: 2px; border-radius: 3px; background: var(--line)}
.more .item .title {font-size: 1.7rem; font-weight: 500; text-decoration: underline; text-underline-offset: 4px; text-decoration-thickness: 2px}
.more .item .title:hover,
.more .item .title:focus {color: #01AAFF}
.more .date {font-size: 1.6rem; text-align: center; opacity: .7}
.more .petals {position: relative}
.more .petals::before {content: ''; position: absolute; left: 100%; top: 0; transform: translate(-60%, -95%); width: 50%; height: 35%; max-width: 416px; max-height: 490px; background: url(../images/illustration/petals.svg) no-repeat 0 0 / auto 100%}
.more .petals > * {position: relative}
.more .petals-2{position: relative}
.more .petals-2::before {content: ''; position: absolute; left: 91.7%; top: 0; background: url(../images/illustration/petals-2.svg) no-repeat left 0 / contain; max-width: 222px; max-height: 255px; aspect-ratio: 222 / 255; width: 100%; height: 100%; transform: translateY(-63%)}

/* CIRCLE BUTTONS */
.circle-buttons {pointer-events: none; display: flex; flex-wrap: wrap; justify-content: center; gap: 10px 20px; grid-area: buttons}
.circle-buttons > li {position: relative}
.circle-buttons .item {--size: 110px; pointer-events: auto; width: var(--size); height: var(--size); border-radius: 50%; position: relative; padding: 20px; text-align: center; transition: transform .2s ease-in-out; display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 10px}
.circle-buttons .item::after {content: ''; background: #F2F4F8; box-shadow: 0 2.78px 2.78px 0 rgba(0,0,0,0.17); border-radius: 50%; position: absolute; width: 100%; height: 100%; z-index: 1}
.circle-buttons .item::before {background: url(../images/illustration/isolation-mode.svg) no-repeat center center / cover; position: absolute; transform: translate(-3%, -3.5%); width: 113.64%; height: 114.4%; top: initial; left: initial; z-index: 0}
.circle-buttons .item > * {position: relative; z-index: 2}
.circle-buttons .icon {display: inline-flex}
.circle-buttons img {max-width: 40px; max-height: 40px; filter: brightness(0) saturate(100%) invert(70%) sepia(21%) saturate(5974%) hue-rotate(158deg) brightness(103%) contrast(105%)}
.circle-buttons .caption {letter-spacing: -.5px; font-size: 1.45rem; font-weight: 600; line-height: 15px; text-wrap: balance}
.circle-buttons:hover img {filter: brightness(0) saturate(100%) invert(56%) sepia(0%) saturate(692%) hue-rotate(233deg) brightness(94%) contrast(86%)}
.circle-buttons:hover .item::after {background: rgba(242,244,248,.8)}
.circle-buttons:hover .-over .item:after {background: #FFF}
.circle-buttons .-over {z-index: 3}
.circle-buttons .-over .item {text-decoration: none; outline: none; color: #01AAFF; transform: translateY(-10%)}
.circle-buttons .-over .item::before {content: ''}
.circle-buttons .-over img {filter: none}
.circle-buttons .share{pointer-events:all; opacity: 0; transition: all .2s ease-in-out; position: absolute; left: 50%; top: 95%; transform: translateX(-50%); background: #129180; padding: 10px; border-radius: 999px; display: flex; gap: 10px}
.circle-buttons .share a {width: 40px; height: 40px; background: no-repeat 0 0 / cover; font-size: 0; border-radius: 50%; filter: brightness(0) invert(1)}
.circle-buttons .share .facebook {background-image: url(../images/icon/facebook.svg)}
.circle-buttons .share .twitter {background-image: url(../images/icon/twitter.svg)}
.circle-buttons .share .linkedin {background-image: url(../images/icon/linkedIn.svg)}
.circle-buttons .share .whatsapp {background-image: url(../images/icon/whatsapp.svg)}
.circle-buttons .-over .share {opacity: 1}

/* ACTIONS */
.actions {background: #129180; padding-top: 30px}
.actions .container {display: flex; flex-direction: column; align-items: center; gap: 30px}
.actions .headline {color: #FFF; font-weight: 600; text-align: center}
.actions .text {flex: 1}

/* SCROLL ANIMATION */
.to-right {opacity: 0; transform: translate3d(-25vw,0,0); transition: .5s}
.to-left {opacity: 0; transform: translate3d(25vw,0,0); transition: .5s}
.to-up {opacity: 0; transform: translate3d(0,25vh,0); transition: .5s}
.to-down {opacity: 0; transform: translate3d(0,-25vh,0); transition: .5s}
.to-end {opacity: 1; transform: translate3d(0,0,0)}

/* BOXES */
.boxes {display: grid; gap: 30px}
.boxes .item {background: #FFF; border: 2px solid #FFF; border-radius: 10px; padding: 30px 21px 39px; display: flex; flex-direction: column; align-items: flex-start}
.boxes .item:hover,
.boxes .item:focus {border-color: #12D0FF}
.boxes .top {margin-bottom: 18px; display: flex; align-items: center; gap: 20px}
.boxes .icon {align-self: center}
.boxes .icon img {height: revert-layer}
.boxes .title {color: #01AAFF; font-size: 2.2rem; font-weight: 500; line-height: 1.1}
.boxes .description {flex-direction: column; flex: 1}
.boxes a:not(.button),
.boxes a:not(.button):hover,
.boxes a:not(.button):focus {color: inherit; text-decoration: none !important}
.boxes strong {color:#01AAFF; font-weight: 500}
.boxes .button {text-decoration: none !important; width: 100%}
.boxes .button:hover,
.boxes .button:focus {color: #FFF !important}
.boxes .columns {display: grid !important; gap: 15px}
.boxes p ~ * {margin-top: 35px !important}
.boxes .-lg {font-size: 2.1rem}
.boxes .-gray {background:#F2F4F8; border-color: #F2F4F8}
.boxes .-green {background:#109080; border-color: #109080; color: #FFF}
.boxes .-green .title {color: inherit}
.boxes .-blue {background:#01AAFF; border-color: #01AAFF; color: #FFF}
.boxes .-blue .icon {filter: brightness(0) invert(1)}
.boxes .-blue .title {color: inherit}

/* GENERIC FILTERS */
.generic-filters {padding-bottom: 20px}
.generic-filters .form {display: flex; flex-direction: column; gap: 20px}
.generic-filters fieldset {display: flex; flex-direction: column; gap: 5px}
.generic-filters .dates {display: grid; grid-template-columns: 1fr 1fr; grid-template-rows: auto 1fr; gap: 5px 20px; grid-template-areas: "label label" "start end"}
.generic-filters .dates label {grid-area: label}
.generic-filters .dates input:first-of-type {grid-area: start}
.generic-filters .dates input:last-of-type {grid-area: end}
.generic-filters .categories {flex: 1}
.generic-filters .text {position: relative}
.generic-filters .text input {padding-right: 75px; width: 100%}
.generic-filters .buttons {position: absolute; right: 15px; top: 0; height: 100%; display: flex; gap: 10px}
.generic-filters [class^='btn'] {height: 100%; font-size: 0; background: none no-repeat center center; filter: brightness(0) saturate(100%) invert(0%) sepia(35%) saturate(4995%) hue-rotate(144deg) brightness(65%) contrast(82%)}
.generic-filters .btn-keyboard {display: none; background-image: url(../images/icon/keyboard.svg); background-size: 23px auto; width: 23px}
.generic-filters .btn-submit {font-size: 0; background-image: url(../images/icon/search.svg); background-size: 22px auto; width: 22px; filter: none}

/* TABLE */
.table-responsive {width: calc(100% + 12px); margin: 0 -6px; padding: 0 6px; overflow-x: auto; overflow-y: clip}
.table-responsive.-no-recuo {width: 100%; margin: 0; padding: 0}
.table-responsive .table {margin: 0 0 10px}
.table {width: 100%; border-collapse: collapse}
.table.col-striped td:nth-child(even) {background: #F2F4F8}
.table tr {position: relative; border-bottom: 2px solid #82cb87}
.table tr::after {content: ''; width: calc(100% + 12px); height: 2px; position: absolute; left: -6px; bottom: -3px; z-index: 1; border-radius: 4px; background: var(--line)}
@supports (-webkit-touch-callout: none) { .table tr::after {content: none} }
.table th {font-size: 2.5rem; font-weight: 500; color: #01AAFF}
.table th,
.table td {padding: 15px 20px; text-align: initial; position: relative; background: #FFF}
.table th:not(.-no-first):first-of-type,
.table td:not(.-no-first):first-of-type {padding-left: 0}
.table [rowspan]::before {content: ''; display: block; background: inherit; width: 100%; height: calc(100% - 2px); position: absolute; top: 2px; left: 0; z-index: 2;}
.table th:first-of-type::before,
.table td:first-of-type::before {width: calc(100% + 6px); left: -6px}
.table th:last-of-type::before,
.table td:last-of-type::before {width: calc(100% + 6px)}
.table [rowspan] > * {position: relative; z-index: 2;}
.table td a {text-decoration: none !important}
.table td .-link {font-weight: 500; color: #01AAFF}
.table td .-link:hover,
.table td .-link:focus {color: #12D0FF}
.table-responsive::-webkit-scrollbar {background: #DDD; height: 5px; width: 5px; border-radius: 5px}
.table-responsive::-webkit-scrollbar-thumb {background: #01AAFF;border-radius: 5px}
.table2 {width: calc(100% - 4px); border-collapse: collapse; position: relative; margin: 2px}
.table2::before {content: ''; border-radius: 10px; border: 2px solid #01AAFF; position: absolute; left: -2px; top: -2px; width: 100%; height: 100%}
.table2 th,
.table2 td {padding: 15px 20px; text-align: initial; position: relative}
.table2 th:not(:first-child)::before,
.table2 td:not(:first-child)::before {content: ''; position: absolute; top: 0; left: -1px; width: 2px; height: 100%; background: #01AAFF; border-radius: 3px}
.table2 tr:first-child th:before {top: 10px}
.table2 tr:last-child td:before {bottom: 10px; top: auto}
.table2 th {background: #BEEFFD}
.table2 th:first-child {border-top-left-radius: 8px}
.table2 th:last-child {border-top-right-radius: 8px}
.table2 tr:last-child td:first-child {border-bottom-left-radius: 8px}
.table2 tr:last-child td:last-child {border-bottom-right-radius: 8px}
.table2.-row-striped tr:nth-child(even) td {background: #BEEFFD}
.table2.-center th,
.table2.-center td {text-align: center}
.table2.-txt-lg {font-size: 166.6666%}

/* ACTION ICON */
.action-icon {font-size: 1.2rem; line-height: 1; text-decoration: none !important; display: inline-flex; flex-direction: column; align-items: center; gap: 7px}
.action-icon:before {content: ''; position: relative; z-index: 1; display: inline-block; background: no-repeat center center / cover; transition: transform .2s ease-in-out}
.action-icon.-download:before {background-image: url(../images/icon/download.svg); width: 35px; height: 44px}
.action-icon.-link:before {background-image: url(../images/icon/link-intact.svg); width: 38px; height: 37px}
.action-icon.-link-external:before {background-image: url(../images/icon/link-external.svg); width: 39px; height: 39px}
.action-icon:before {transform: translateY(12px)}
.action-icon > span {transform: translateY(-12px); opacity: 0; min-width: max-content; transition: all .3s ease-in-out}
.action-icon:hover:before,
.action-icon:focus:before {transform: translateY(0); filter: brightness(0) saturate(100%) invert(52%) sepia(64%) saturate(3536%) hue-rotate(173deg) brightness(103%) contrast(105%)}
.action-icon:hover > span,
.action-icon:focus > span {transform: translateY(0); opacity: 1; color: #171717}

/* PICTURE */
.picture {position: relative; display: inline-flex; flex-direction: column; align-items: center; justify-content: center}
.picture img {border-radius: 10px}
.picture.-full {width: 100%}
.picture.-border {border-radius: 10px; border: 2px solid #01AAFF}
.picture.-contain {padding: 10px}
.picture.-radius {border-radius: 10px}
.picture.-white {background: #FFF}
.picture.petals::before {content: ''; position: absolute; left: 90%; top: 50%; background: url(../images/illustration/petals-2.svg) no-repeat left 0 / contain; max-width: 235px; max-height: 268px; aspect-ratio: 235/268; width: 100%; height: 72%; transform: translateY(-96.2%)}

/* CARROSSEL */
.carousel {position: relative}
.carousel .tns-visually-hidden {display: none}
.carousel .item {padding: 53px 0 110px; color: #FFF}
.carousel .item a {color: inherit}
.carousel .item a:hover,
.carousel .item a:focus {color: inherit; filter: inherit}
.carousel .item .-white:hover:after,
.carousel .item .-white:focus:after {filter: brightness(0) invert(1)}
.carousel .item .-black:hover:after,
.carousel .item .-black:focus:after {filter: brightness(0) invert(0)}
.carousel .carousel-slider > .item:not(.tns-slide-active) {height: 0; overflow: hidden; opacity: 0}
.carousel .tns-slider > .item:not(.tns-slide-active) {height: auto; overflow: initial; opacity: 1}
.carousel .-green {background-color: #127101}
.carousel .-green-teal {background-color: #129180}
.carousel .-blue {background-color: #01AAFF}
.carousel .-dark-blue {background-color: #01AAFF}
.carousel .-tangelo {background-color: #FAB180}
.carousel .container {min-height: 300px; display: grid; gap: 20px}
.carousel .title {font-size: 3rem; font-weight: 500; line-height: normal; margin-bottom: 8px}
.carousel .description {font-size: 1.8rem; line-height: normal; margin-bottom: 22px}
.carousel .tns-controls {position: absolute; z-index: 1; bottom: 50px; left: 50%; transform: translateX(-50%); min-width: 155px; width: calc(70px + var(--nav-width)); height: 0; display: flex; justify-content: space-between}
.carousel .tns-controls [aria-controls] {background: none; font-size: 0; width: 24px; height: 24px; border-radius: 50%; transform: translateY(-50%)}
.carousel .tns-controls [aria-controls]:after {content: ''; width: 100%; height: 100%; background: url(../images/icon/arrow-down.svg) no-repeat center center / 58% auto; display: inline-block; transform: rotate(-90deg); filter: brightness(0) invert(1)}
.carousel .tns-controls [data-controls="prev"]:after {transform: rotate(90deg)}
.carousel .tns-nav {position: absolute; z-index: 0; bottom: 53px; left: 50%; transform: translate(-50%, 50%); display: flex; gap: 13px}
.carousel .tns-nav button {position: relative; background: none; border: 0; border-radius: 50%}
.carousel .tns-nav span {border-radius: 50%; font-family: Satoshi, sans-serif; font-size: 2.4rem; font-weight: 500; color: #FFF; aspect-ratio: 1; min-width: 35px; display: flex; justify-content: center; align-items: center}
.carousel .tns-nav .tns-nav-active span {position: relative; background: #FFF; color: #01AAFF; font-weight: 900}
.carousel .tns-nav .tns-nav-active::before {content: ''; display: block; background: url(../images/illustration/isolation-mode.svg) no-repeat 0 0 / cover; width: 42px; height: 42px; position: absolute; bottom: -4px; right: 0}
.carousel [data-nav-color="black"] .item {color: #171717}
.carousel [data-nav-color="black"] [data-nav]:not(.tns-nav-active) span {color: #171717}
.carousel [data-nav-color="black"] [aria-controls]:after {filter: brightness(0)}
.carousel .picture,
.carousel .figure {border-radius: 10px; overflow: hidden; max-height: 300px; aspect-ratio: 640/300}
.carousel img[data-src] {opacity: 1}
.carousel img[src=""] {aspect-ratio: 1}

/* ACCORDION */
.accordion {display: grid; gap: 25px}
.accordion .item {background: #FFF; border-radius: 10px; border: 2px solid #DEDEDE; max-height: min-content; overflow: hidden; transition: height 4s ease-in}
.accordion .head a {padding: 15px 20px; border-radius: 8px; text-decoration: none; font-size: 1.8rem; font-weight: 600; display: flex; justify-content: space-between; align-items: center; gap: 15px}
.accordion .head a:after {content: ''; background: url(../images/icon/arrow-down.svg) no-repeat center center / contain; min-width: 18px; height: 14px; margin-bottom: -2px; transition: transform .5s ease-in-out; filter: brightness(0)}
.accordion .collapse {display: block; width: 100%; overflow: hidden; max-height: 0; transition: max-height 1s ease-in-out}
.accordion .body {position: relative; width: 100%; padding: 0 20px 40px}
.accordion .close {font-size: 0; position: absolute; z-index: 3; right: 22px; bottom: 16px; background: url(../images/icon/x.svg) no-repeat center center / cover; width: 14px; height: 14px; transform: none}
.accordion .item:hover {border-color: #01AAFF}
.accordion .item:has(a:focus) {border-color: #01AAFF}
.accordion .head a:focus {outline: 0}
.accordion .head a:focus:after,
.accordion .head a:hover:after {filter: none}
.accordion .-open {border-color: #01AAFF; height: max-content}
.accordion .-open .head a {color: #01AAFF; outline: none}
.accordion .-open .head a:after {filter: none; transform: rotate(180deg)}
.accordion .-open .collapse {max-height: max-content}
.accordion .columns {display: grid; gap: 45px}
.accordion .columns .column {position: relative}
.accordion .columns .column:not(:first-child):before {content: ''; border-radius: 9px; position: absolute; top: -23px; left: 0; height: 2px; width: 100%; background: var(--line)}
.-accordion {gap: 16px}
.-accordion .item {box-shadow: 0 3px 3px rgba(0,0,0,.13)}
.-accordion .head a:after {transform: rotate(-90deg)}
.-accordion .-open {box-shadow: none}
.-accordion .-open .head a:after {background-image: url(../images/icon/x.svg); width: 22px; height: 23px; transform: none}
.accordion .-open.-no-overflow,
.accordion .-open.-no-overflow .collapse {overflow: visible}

/* BOARDS */
.boards {display: grid; gap: 30px}
.boards .item {background: #FFF; border: 2px solid #FFF; border-radius: 10px; display: flex}
.boards .item:hover,
.boards .item:focus {border-color: transparent; outline: 2px solid #12D0FF; outline-offset: -2px}
.boards .figure {background: #E5E8EF; border-radius: 10px 0 0 10px; margin: -2px; width: 145px}
.boards .figure a {display: block}
.boards .figure img {background: #E5E8EF; border-radius: 8px 0 0 8px; border: 2px solid #E5E8EF; width: 100%; aspect-ratio: 192 / 257}
.boards .info {padding: 28px 10px 31px 20px; min-height: 190px; display: flex; flex-direction: column; justify-content: space-between; align-items: flex-start; flex: 1}
.boards .title {color: #01AAFF; font-size: 2.2rem; font-weight: 500; line-height: normal; margin-bottom: 4px; text-wrap: balance}
.boards .subtitle {color: #01AAFF; font-size: 1.8rem; font-weight: 500; line-height: normal; margin-bottom: 4px; text-wrap: balance}
.boards .description {flex: 1}
.boards .item a,
.boards .item a:hover,
.boards .item a:focus {color: inherit; text-decoration: none !important}
.boards .link {color: #01AAFF !important}
.boards p ~ * {margin-top: 16px !important}
.boards .-gray {background:#F2F4F8; border-color: #F2F4F8}

/* G COLUMNS */
.g-columns {display: grid; --gap: 30px; gap: var(--gap)}

/* SUPERCARDS */
.supercards .item {background: #FFF; border-radius: 10px; display: flex; flex-direction: column}
.supercards .-gray {background: #F2F4F8}
.supercards .item:hover,
.supercards .item:focus {outline: 2px solid #01AAFF; outline-offset: -2px}
.supercards img {border-bottom-left-radius: 0; border-bottom-right-radius: 0}
.supercards .info {padding: 39px 21px}
.supercards .picture + .info {padding-top: 21px}
.supercards .title {font-size: 2.2rem; font-weight: 500; color: #01AAFF; margin-bottom: 22px}

/* BASICCARDS */
.basiccards > .item {background: #FFF; border: 2px solid #01AAFF; border-radius: 10px; padding: 28px 21px}
.basiccards > .item.-center {display: grid; justify-content: center}
.basiccards > .-gray {background: #F2F4F8}

/* IMAGEMAP */
.imagemap {position: relative; display: inline-block}
.imagemap .area {--map-left: var(--map-left-mobile); --map-top: var(--map-top-mobile); --map-width: var(--map-width-mobile); --map-height: var(--map-height-mobile); position: absolute; z-index: 1; left: var(--map-left, 0); top: var(--map-top, 0); width: var(--map-width, 50%); height: var(--map-height, 10%)}
.imagemap .shape {border-radius: 10px; font-size: 0; opacity: 0; width: 100%; height: 100%; cursor: pointer; display: block}
.imagemap .shape::after {content: ''; height: 20%; width: 100%; position: absolute; top: 100%; left: 0}
.imagemap .popover {display: none; position: absolute; top: 110%; left: 50%; transform: translateX(var(--popover-left, -50%)); background: #FFF; border: 2px solid #01AAFF; border-radius: 22px; padding: 20px 15px; font-size: 1.8rem; text-align: left; min-width: 290px; width: calc(91.3vw - 30px)}
.imagemap .area:hover {z-index: 2}
.imagemap .area:hover .popover {display: block}
.imagemap .shape:focus ~ .popover {display: block}

/* SPACING */
.-space-sm {margin-bottom: 10px !important}
.-space-md {margin-bottom: 35px !important}
.-space-lg {margin-bottom: 50px !important}
.mt-0 {margin-top: 0 !important}
.mb-0 {margin-bottom: 0 !important}
.mr-0 {margin-right: 0 !important}
.ml-0 {margin-left: 0 !important}
.pt-0 {padding-top: 0 !important}
.pb-0 {padding-bottom: 0 !important}
.pr-0 {padding-right: 0 !important}
.pl-0 {padding-left: 0 !important}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
	/* MORE */
	.more .petals::before {transform: translate(-30%, -95%)}

	/* BOXES */
	.boxes .button {width: 54.435%}
	.boxes .columns {grid-template-columns: 1fr 1fr; gap: 15px 30px}

	/* BOARDS */
	.boards .figure {width: 196px}
	.boards .info {min-height: 239px}

	/* IMAGEMAP */
	.imagemap .area {--map-left: var(--map-left-desk); --map-top: var(--map-top-desk); --map-width: var(--map-width-desk); --map-height: var(--map-height-desk)}
	.imagemap .popover {left: 0; transform: none; max-width: 40vw}
	.imagemap .popover.-right {right: 0; left: auto}
	.imagemap .popover.-center {left: 50%; transform: translateX(-50%)}
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
	/* IMAGE ALIGN */
	.align-right {width: auto}
	.align-left {width: auto}

	/* BOXES */
	.boxes:not(.-full) {grid-template-columns: 1fr 1fr; gap: 40px 58px}

	/* FILTERS */
	.generic-filters .form {flex-direction: row; align-items: flex-end; gap: 35px}
	.generic-filters .cities {max-width: 299px; flex: 1}
	.generic-filters .dates input {max-width: 160px}
	.generic-filters .order {max-width: 150px}
	.generic-filters .text {max-width: 237px}
	.generic-filters .categories {max-width: 299px}
	.generic-filters .btn-keyboard {display: inline-flex}

	/* CAROUSEL */
	.carousel .container {display: grid; grid-template-columns: 49.4% 1fr; gap: 54px}

	/* BOARDS */
	.boards:not(.-full) {grid-template-columns: 1fr 1fr; gap: 40px 18px}
	.boards .figure {width: 196px}
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
	/* BANNER */
	.banner {margin-bottom: 39px}

	/* CIRCLE BUTTONS */
	.circle-buttons {gap: 66px}
	.circle-buttons .item {--size: 132px}
	.circle-buttons img {max-width: 40px; max-height: 40px}
	.circle-buttons .caption {font-size: 1.5rem; line-height: 17px}

	/* ACTIONS */
	.actions {padding-top: 0}
	.actions::before {content: ''; display: block; background: #FFF; height: 90px}
	.actions .container {flex-direction: row-reverse; justify-content: space-between; align-items: center}
	.actions .figure {margin: -77px 0 0 -31px}

	/* PICTURE */
	.picture.-contain {padding: 20px 13px}

	/* MORE */
	.more {padding: 87px 0 95px}
	.more .columns {display: grid; grid-auto-rows: auto; grid-template-columns: 1fr 1fr; gap: 4.2%}
	.more .petals::before {max-width: 416px; max-height: 490px; height: 134.55%; transform: translate(-62%, -85%)}

	/* FILTERS */
	.generic-filters {padding-bottom: 38px}
	.generic-filters .form {gap: 50px}
	.generic-filters fieldset,
	.generic-filters .dates {display: flex; flex-direction: row; align-items: center; gap: 20px}
	.generic-filters .order {max-width: 178px}
	.generic-filters .text {width: 272px; max-width: none}
	.generic-filters .text input {padding-right: 50px}

	/* TABLE */
	.table th,
	.table td {padding: 20px 62px}

	/* ACCORDION */
	.accordion {gap: 40px}
	.accordion .head a {padding: 22.5px 50px 25px 25px; gap: 30px}
	.accordion .head a:after {min-width: 25px}
	.accordion .body {padding-inline: 25px 50px}
	.accordion .close {right: 56px}
	.accordion .columns {grid-template-columns: 1fr 1fr; gap: 40px 52px}
	.accordion .columns.-auto {grid-template-columns: auto auto}
	.accordion .columns.-auto-max {grid-template-columns: auto max-content}
	.accordion .columns.-v-center {align-items: center}
	.accordion .columns .column:not(:first-child):before {top: 0; left: -27px; height: 100%; width: 2px; background: var(--line-v)}
	.accordion .columns .column:not(:nth-child(even)):before {content: none}
	.-accordion {gap: 26px}
	.-accordion .body {padding-right: 50px}
	.accordion.-columns {grid-template-columns: 1fr 1fr; align-items: start; grid-auto-flow: column; grid-template-rows: repeat(var(--half-items), auto)}

	/* BOARDS */
	.boards:not(.-full) {grid-template-columns: 1fr 1fr 1fr}

	/* G COLUMNS */
	.g-columns {--gap: 65px; grid-template-columns: 1fr 1fr}
	.g-columns.-gap-sm {--gap: 25px}
	.g-columns.-gap-left {grid-template-columns: calc(50% - var(--gap)) 1fr}
	.g-columns.-gap-right {grid-template-columns: 1fr calc(50% - var(--gap))}
	.g-columns.-auto-max {grid-template-columns: auto max-content}
	.g-columns.-max-auto {grid-template-columns: max-content auto}
	.g-columns.-v-center {align-items: center}
	.g-columns.-reverse {grid-auto-flow: dense}
	.g-columns.-reverse > :first-child {grid-column: 2}

	/* IMAGEMAP */
	.imagemap .popover {font-size: 2.2rem; padding: 35px 28px; width: var(--popover-width, auto); max-width: 90vw}
}
