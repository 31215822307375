/* GENERAL */
.clear::after {display: block; content: ""; clear: both}
.-no-shadow {box-shadow: none !important}
.-no-radius {border-radius: 0 !important}
.drop-link {font-size: 2.1rem; position: relative; z-index: 1}
.drop-link:not(.button):hover:before {content: ''; display: block; width: 42px; height: 19px; position: absolute; left: 0; top: 0; transform: translate(-32%, 60%); background: url(../images/icon/petal.svg) no-repeat 0 0 / contain; z-index: -1}

/* .MAIN */
.main {display: flex; flex-direction: column}
.main img[data-src] {opacity: 0}
.main img {opacity: 1; transition: opacity .3s ease-in}
.main img[src*=".jpg"] {background: #E5E8EF}

/* BREADCRUMBS */
.main .breadcrumbs {padding: 20px 0}
.main .breadcrumbs .container {display: flex; justify-content: space-between; flex-wrap: wrap; gap: 20px}
.main .breadcrumbs ul {min-height: 35px; display: flex; flex-wrap: wrap}
.main .breadcrumbs .menu li {font-size: 1.3rem; display: inline-flex; align-items: center}
.main .breadcrumbs .menu li:not(:first-child)::before {content: '>'; color: #8B8B8B; font-weight: 400 !important; margin: 0 4px; display: inline-flex; align-items: center}
.main .breadcrumbs .menu a {color: #8B8B8B; min-width: max-content}
.main .breadcrumbs .menu li:last-of-type {color: #01AAFF; font-family: Satoshi, sans-serif; font-weight: 600}
.main .breadcrumbs .menu li:last-of-type a {color: inherit; cursor: no-drop; pointer-events:all}
.main .breadcrumbs .buttons {justify-content: flex-end; flex: 1; gap: 30px}
.main .breadcrumbs .button {line-height: 1.02; min-height: 35px; height: 100%; padding-top: 5px; padding-bottom: 5px; gap: 18px}
.main .breadcrumbs .button::before {display: inline-flex; background: no-repeat center center / cover}
.main .breadcrumbs .-home::before {content: ''; width: 25px; height: 25px; background-image: url(../images/icon/home.svg)}
.main .breadcrumbs .-back::before {content: ''; width: 17px; height: 11px; background-image: url(../images/icon/arrow-down.svg); transform: rotate(90deg); filter: brightness(0) invert(1)}

/* PUBLISHED */
.published {font-size: 1.4rem; margin: -7px 0 10px; opacity: .7}

/* FORM */
.main fieldset {border: 0}
.main label {font-size: 1.5rem; font-weight: 500; text-wrap: balance; width: 100%}
.main input,
.main .select {font-family: 'Satoshi', sans-serif; font-size: 1.5rem; font-weight: 500; letter-spacing: -0.3px; background: #FFF; box-shadow: 0 6px 6px rgbA(147,153,176, .1); color: #6F7A88; border: 1px solid #D0D0D0; border-radius: 10px; width: 100%; min-height: 40px; padding: 9px 20px}
.main .select {position: relative; padding: 0}
.main .select select {font-family: 'Satoshi', sans-serif; position: relative; z-index: 1; padding: 10px 45px 10px 20px; width: 100%; min-height: 38px; display: block; background: none; border: 0; font-size: inherit; font-weight: inherit; color: inherit; appearance: none}
.main .select .icon {content: ""; width: 10px; height: 10px; display: block; position: absolute; z-index: 0; right: 25px; top: 50%; background: url(../images/icon/arrow-down.svg) no-repeat center center / 10px auto; transform: translateY(-50%); filter: brightness(0) saturate(100%) invert(5%) sepia(9%) saturate(149%) hue-rotate(314deg) brightness(103%) contrast(91%)}
.main select:focus ~ .icon {transform: scale(-1,-1) translateY(50%)}
.main input[type="date"]::-webkit-calendar-picker-indicator {cursor: pointer; filter: brightness(0) saturate(100%) invert(5%) sepia(9%) saturate(149%) hue-rotate(314deg) brightness(103%) contrast(91%); opacity: 1}
.main input[type="date"]::-webkit-calendar-picker-indicator:hover {opacity: 1}

/* CONTENT */
.main .content {background: #FFF; font-size: 1.8rem; padding: 43px 0; flex: 1}
.main .content.-gray {background: #f2f4f8}
.main .content a {text-decoration: underline; text-underline-offset: 4px; text-decoration-thickness: 2px}
.main .content a:hover,
.main .content a:focus {color: #01AAFF}
.main .content a.-internal,
.main .content a.-external,
.main .content a.-external-2,
.main .content a.-mail,
.main .content a.-phone,
.main .content a.-download2 {transition: none}
.main .content a.-internal:after,
.main .content a.-external:after,
.main .content a.-external-2:after,
.main .content a.-mail:after,
.main .content a.-phone:after,
.main .content a.-download2:after {content: ''; display: inline-block; background: no-repeat center center / 100% auto; margin-left: 10px; transform: none; filter: brightness(0)}
.main .content a.-internal:after {background-image: url(../images/icon/arrow-down.svg); width: 12px; height: 12px; margin-bottom: -2px; transform: rotate(-90deg)}
.main .content a.-external:after {background-image: url(../images/icon/link.svg); width: 13px; height: 14px}
.main .content a.-external-2:after {background-image: url(../images/icon/link-external.svg); width: 12px; height: 12px}
.main .content a.-download2:after {background-image: url(../images/icon/download.svg); width: 12px; height: 14px}
.main .content a.-mail:after {content: ''; display: inline-block; background: url(../images/icon/mail.svg) no-repeat center center / 100% auto; width: 16px; height: 11px; margin-left: 10px; transform: none; filter: brightness(0)}
.main .content a.-phone:after {content: ''; display: inline-block; background: url(../images/icon/phone.svg) no-repeat center center / 100% auto; width: 13px; height: 13px; margin-left: 10px; transform: none; filter: brightness(0)}
.main .content a.-internal:hover:after,
.main .content a.-internal:focus:after,
.main .content a.-external:hover:after,
.main .content a.-external:focus:after,
.main .content a.-external-2:hover:after,
.main .content a.-external-2:focus:after,
.main .content a.-mail:hover:after,
.main .content a.-mail:focus:after,
.main .content a.-phone:hover:after,
.main .content a.-phone:focus:after,
.main .content a.-download2:hover:after,
.main .content a.-download2:focus:after  {filter: brightness(0) saturate(100%) invert(49%) sepia(72%) saturate(2252%) hue-rotate(171deg) brightness(99%) contrast(107%)}
.main .content p ~ * {margin-top: 23px}
.main .content ol,
.main .content ul {display: flex; flex-direction: column; gap: 8px}
.main .content ol.-gap-lg,
.main .content ul.-gap-lg {gap: 34px}
.main .content ol {padding-left: 2em}
.main .content ol > li {list-style: outside decimal}
.main .content [type="a"] > li {list-style-type: lower-alpha}
.main .content [type="i"] > li {list-style-type: lower-roman}
.main .content .alpha {counter-reset: counter; list-style-type: none}
.main .content .alpha > li {display: block}
.main .content .alpha > li:before {margin-left: -1em; content: counter(counter, lower-latin) ") "; counter-increment: counter}
.main .content .roman > li {list-style: outside lower-roman}
.main .content .numeric {counter-reset: counter; list-style: none}
.main .content .numeric li {counter-increment: counter; position: relative; list-style: none}
.main .content .numeric li::before {content: counter(counter); border-radius: 50%; background: #FF8A01; color: #fff; font-size: 1.5rem; font-weight: 900; --size: 20px; position: absolute; top: 0; left: -2.4em; min-width: var(--size); min-height: var(--size); padding: 2px; aspect-ratio: 1; display: inline-flex; justify-content: center; align-items: center}
.main .content .numeric.-blue li::before {background: #01AAFF}
.main .content li > img {margin-bottom: -2px}
.main .content .list {padding-left: 35px}
.main .content .list li {position: relative}
.main .content .list li::before {content: ''; display: inline-block; position: absolute; top: 3px; left: -35px; background: url(../images/icon/marker.svg) no-repeat 0 0 / cover; height: 20px; aspect-ratio: 1}
.main .content .list.-auto-row {flex-direction: row; justify-content: space-between; flex-wrap: wrap; gap: 9px 40px}
.main .content br {font-size: 0}
.main .content p ~ .lead {margin-top: 38px}
.main .content small {line-height: 1; font-size: 88.9%}
.main .content sup,
.main .content sub {font-size: 60%; vertical-align: super; line-height: 1}
.main .content hr {border: 0; margin: 3.5em 0; width: 100%; height: 2px; border-radius: 3px; background: var(--line)}
.main .content hr.-gap-md {margin: 2.6em 0}
.main .content b {font-weight: 500}
.main .content .lead {font-size: 2.1rem}
.main .content .lead strong {font-weight: 500}
.main .content .subheadline {font-size: 2.2rem; font-weight: 500; line-height: 1.2; color: #01AAFF; margin-bottom: 17px}
.main .content .subheadline + p {margin-top: 0}
.main .content * ~ .subheadline {margin-top: 38px}
.main .content .container > h2:not(.headline) {font-size: 2.2rem}
.main .content .button {min-width: 230px}
.main .content .-balance {text-wrap: balance}
.main .content .-fw-medium {font-weight: 500}
.main .content .-txt-blue {color: #01AAFF}
.main .content .-txt-gray {color: #5d5d5d}
.main .content .-no-underline {text-decoration: none !important}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
	/* CONTENT */
	.main .content .button {min-width: 313px}
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
	/* GENERAL */
	.headline {margin-bottom: 30px}

	/* PUBLISHED */
	.published {font-size: 1.6rem; margin: -15px 0 14px}
}
